.questions-list {
    --question-h: 4rem;
    --question-m: 0.5rem;

    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    position: relative;
}

@media (min-width: 640px) {
    .questions-list {
        --question-h: 5rem;
        --question-m: 1rem;
    }
}

.question-body {
    display: flex;
    flex-direction: column;
}

.questions-list:before {
    content: "";
    position: absolute;
    height: calc(100% - var(--question-h) + 0.125rem);
    width: 0.125rem;
    left: -1.125rem;
    background-color: var(--udes-color);
    top: 50%;
    transform: translateY(-50%);
}

section.question {
    min-height: var(--body-height);
    position: relative;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

section.question:not(:first-of-type) {
    @apply mt-5 sm:mt-0;
}

@media (min-width: 640px) {
    section.question {
        height: var(--body-height);
    }
}

section.question header {
    position: relative;

    @apply text-lg sm:text-4xl;
}

section.question header:after {
    position: absolute;
    content: attr(data-section);
    left: 0;
    right: 0;
    bottom: -1rem;
    background-color: white;
    font-weight: 500;
    width: fit-content;

    @apply p-2 text-lg rounded-xl text-udes-100 rounded-tl-none mx-auto sm:mx-0 hidden sm:block;
}

section.question li {
    height: var(--question-h);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eee;
    border-radius: 0.6rem;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: border-color 0.2s ease-in-out;
    background-color: white;
    width: min(50rem, 90vw);

    @apply text-base sm:text-xl
}

section.question li:before {
    content: "";
    position: absolute;
    height: 0.125rem;
    width: 1rem;
    background-color: var(--udes-color);
    left: -1.125rem;
}

section.question li:not(:last-of-type) {
    margin-bottom: var(--question-m);
}

section.question li:after {
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    margin-left: 1rem;
    content: '\f111';
    color: var(--udes-color);
}

section.question li:not(.good):not(.bad):not(.locked):hover:after {
    content: '\f192';
}

section.question li.good:after,
section.question li.answer:after {
    content: var(--icon-good);
    color: inherit;
}

section.question li.bad:after {
    content: var(--icon-bad);
    color: inherit;
}

section.question li button {
    background-color: var(--udes-color);
}

section.question li:hover:not(.locked),
section.question li.selected:not(.locked) {
    background-color: #FAFAFA;
    border-color: var(--udes-color);
    cursor: pointer;
}

section.question li.selected.good {
    border-color: var(--border-color-good);
    background-color: var(--bc-color--good);
}

section.question li.selected.good,
section.question li.answer {
    color: var(--good);
}

section.question li.answer:not(.good) {
    border-color: var(--border-color-yellow);
    color: var(--yellow);
    background-color: var(--bc-color-yellow);
}

section.question li.selected.bad {
    border-color: var(--border-color-bad);
    color: var(--bad);
    background-color: var(--bc-color--bad);
}

.reason {
    display: flex;
    align-items: center;
    background-color: #F0F0F0;

    @apply sm:text-xl sm:text-xl p-3 sm:p-5;
}

.reason.good {
    background-color: var(--bc-color--good);
}

.reason.bad {
    background-color: var(--bc-color--bad);
}

.questions-container img {
    width: 15rem;
}
