.navigation {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    @apply text-xl rounded-xl;
    color: #434343;
    background: white;
    margin: var(--navigation-margin);
    margin-bottom: 0;
}

.navigation + * {
    margin-top: var(--navigation-margin);
}

.navigation a[aria-current="page"],
.navigation a:hover
{
    @apply text-udes-100
}

.navigation a:hover,
.navigation a[aria-current="page"]{
    font-weight: 500;
}
