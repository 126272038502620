.home {
    height: var(--body-height);
    opacity: 1;
    animation-name: show;
    animation-duration: 2s;
    @apply pt-0 pt-5rem sm:pt-20rem justify-center;
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.home-title {
    @apply text-2xl sm:text-8xl;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.home-img {
    width: 80%;
}
