.information {
    padding: var(--navigation-margin);
    padding-top: 0;
}

.information article {
    width: 100%;
}

.information article iframe,
.information article img {
    @apply w-2/3 sm:w-full h-10rem sm:h-20rem;
    padding-bottom: var(--navigation-margin);
}

.information article .content {
    width: min(1000px, 95vw);
}

.information article header {
    margin: 0;
    width: 100%;
    @apply p-5 sm:p-12  m-6 sm:m-12;
}

.information article:first-of-type header {
    @apply mt-0;
}

.information article {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.information article > div {
    width: 100%;
    @apply rounded-xl space-y-6 flex justify-center flex-col items-center;
}

.information article section p {
    text-align: justify;
}

.information article section {
    @apply px-6;
}

.information article section:last-of-type {
    @apply pb-6;
}

.biblio a {
    @apply text-udes-100 font-bold underline;
}

.biblio ul {
    list-style: square;

    @apply ml-5 space-y-1.5;
}
