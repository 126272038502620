@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --good: #155724;
    --bc-color--good: #d4edda;
    --border-color-good: #c3e6cb;

    --bad: #721c24;
    --bc-color--bad: #f8d7da;
    --border-color-bad: #f5c6cb;

    --yellow: #6f5404;
    --bc-color-yellow: #fff3cd;
    --border-color-yellow: #ffeeba;

    --udes-color: #018849;

    --reason-height: 5rem;

    --icon-good: "\f058";
    --icon-bad: "\f057";

    --navigation-height: 5vh;
    --navigation-margin: 1rem;
    --body-height: calc(100vh - (var(--navigation-height) + var(--navigation-margin) * 2));
}

* {
    margin: 0;
    padding: 0;
}

body {
    background-color: rgba(230, 243, 237, 0.84);
}

.navigation {
    height: var(--navigation-height);
}

.app {
    height: var(--body-height);
    @apply overflow-y-auto sm:overflow-y-hidden snap-none sm:snap-y snap-mandatory;
}

.scoreboard li {
    list-style: none;
    position: relative;
}

.scoreboard li:before {
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    content: '';
    margin-right: 0.75rem;
}

.scoreboard li.good:before {
    color: var(--good);
    content: var(--icon-good);
}

.scoreboard li.bad:before {
    color: var(--bad);
    content: var(--icon-bad);
}

header {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FAFAFA;
    background-color: var(--udes-color);
    margin: 0 var(--navigation-margin);
    @apply p-3 text-center rounded-xl text-xl sm:text-4xl;
}

button.default {
    background-color: var(--udes-color);
    color: white;
    padding: 0.75rem 2.75rem 0.75rem 0.75rem;
    border-radius: 0.5rem;
    position: relative;
    border: none;
    @apply text-base sm:text-xl;
}

button.default:after {
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    font-size: 1.5rem;
    content: "\f358";
    color: white;
    margin-left: 0.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

button.default:hover {
    cursor: pointer;
}

.link {
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    @apply text-white p-2 rounded-md hover:cursor-pointer hover:scale-105 hover:opacity-90;
}
